import React from 'react';

const MapComponent = () => {
  const apiKey = 'AIzaSyAKyPAbTzChusQXyKIbyyWTvYa6hNxXOwY'; // Replace with your Google API Key
  const lat = 25.108128;  // Latitude of the location
  const lng = 55.181493; // Longitude of the location
  const zoom = 12; // Adjust zoom level (1-20)
  const size = '626x410'; // Size of the map image

//   const style = 'style=feature:water|color:0xa2daf2&style=feature:landscape|color:0xd0e3b4&style=feature:road|color:0x80a4d4&style=feature:poi.park|color:0x8fd4bf&style=feature:transit.line|color:0x3a5fcd';
const style = null;
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${size}&${style}&markers=color:red%7C${lat},${lng}&key=${apiKey}`;

  return (
    <div className="map-box container-block">
      <img src={mapUrl}>
      </img>
      </div>
  );
};

export default MapComponent;