import React from 'react';
import store from '../assets/store.jpg';
import play from '../assets/play.png';
import download from '../assets/download.png';
import '../styles/styles.css';


const DownloadSection = () => (
    <section className="section__container download__container" id="contact">
    <div className="download__grid">
      <div className="download__content">
        <h2 className="section__header">Fleetify applications available soon</h2>
        <p>
          Manage your bookings, find exclusive
          deals, and access 24/7 support, all from your mobile device.
        </p>
        <div className="download__links">
          <a href="#">
            <img src={store} alt="app store" />
          </a>
          <a href="#">
            <img src={play} alt="play" />
          </a>
        </div>
      </div>
      <div className="download__image">
        <img src={download} alt="download"/>
      </div>
    </div>
  </section>
);

export default DownloadSection;