// Layout.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from './AuthContext';


const Layout = ({ children, openModal, handleAuthSuccess }) => {
  const location = useLocation();
  const { handleSignOut, user } = useAuth(); // Access handleSignOut and user from AuthContext

  return (
    <div>
      <Navbar openModal={openModal} handleSignOut={handleSignOut} handleAuthSuccess={handleAuthSuccess} user={user} />
      {location.pathname === '/' && <Header />}
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
