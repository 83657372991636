import React from 'react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaCar, FaUser, FaSuitcase, FaPhone, FaWhatsapp, FaTelegramPlane, FaCheck, FaHeart } from 'react-icons/fa';
import { BsFuelPumpFill } from 'react-icons/bs';
import { GiCarDoor } from "react-icons/gi";
import '../styles/styles.css';
import { useAuth } from './AuthContext';
import LoginSignUp from './LoginSignUp';

const CarListing = ({ openModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentCar, setCurrentCar] = useState(null);
  const [cars, setCars] = useState([]); // State to hold car data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentPage, setCurrentPage] = useState(0); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages from the API
  const [carType, setCarType] = useState(null); // Total pages from the API
  const location = useLocation(); // Get the location object to access query params
  const { user } = useAuth();
  const [likedCarsMap, setLikedCarsMap] = useState({});
  const [likedCarIds, setLikedCarIds] = useState([]);


  useEffect(() => {
    async function fetchLikedCars() {
      if (!user) return;

      try {
        const response = await fetch(`/api/user/liked-cars-ids/${user.uid}`);
        const likedCarIds = await response.json();

        // Map the liked car IDs to a dictionary for easier access
        const likedCarsMap = likedCarIds.reduce((acc, carId) => {
          acc[carId] = true;
          return acc;
        }, {});

        setLikedCarIds(likedCarIds);
        setLikedCarsMap(likedCarsMap);
      } catch (error) {
        console.error("Failed to fetch liked cars:", error);
      }
    }

    fetchLikedCars();
  }, [user]);

  const handleToggleLike = useCallback(async (carId) => {
    if (!user) {
      openModal(LoginSignUp); // Show login modal if user is not logged in
      return;
    }

    const isLiked = likedCarsMap[carId];
    const action = isLiked ? 'remove' : 'addCar';
    const method = isLiked ? 'DELETE' : 'POST';
    let userId = user.uid;

    try {
      setLikedCarsMap((prevLikedCarsMap) => ({
        ...prevLikedCarsMap,
        [carId]: !isLiked,
      }));

      const response = await fetch(
        `/api/user/${userId}/car/${carId}`,
        {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to ${action} for car ID: ${carId}`);
      }

      console.log(`Successfully ${isLiked ? 'removed' : 'added'} car ID: ${carId}`);
    } catch (error) {
      console.error(error);
      setLikedCarsMap((prevLikedCarsMap) => ({
        ...prevLikedCarsMap,
        [carId]: isLiked,
      }));
    }
  }, [user, likedCarsMap, openModal]);

  // Function to get the car type from the URL query params
  const getCarTypeFromQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('type');
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(
      <button
        key={0}
        className={`pagination-button ${currentPage === 0 ? 'active' : ''}`}
        onClick={() => setCurrentPage(0)}
      >
        1
      </button>
    );

    // Add ellipses if necessary before the current page block
    if (currentPage > 3) {
      pageNumbers.push(<span key="start-ellipsis">...</span>);
    }

    // Show pages near the current page
    for (let i = Math.max(1, currentPage - 3); i <= Math.min(currentPage + 3, totalPages - 2); i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
          onClick={() => setCurrentPage(i)}
        >
          {i + 1}
        </button>
      );
    }

    // Add ellipses if necessary after the current page block
    if (currentPage < totalPages - 4) {
      pageNumbers.push(<span key="end-ellipsis">...</span>);
    }

    // Always show the last page
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages - 1}
          className={`pagination-button ${currentPage === totalPages - 1 ? 'active' : ''}`}
          onClick={() => setCurrentPage(totalPages - 1)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  useEffect(() => {
    const fetchCarsByType = async () => {
      const typeFromUrl = getCarTypeFromQueryParams(); // Get car type from the query parameter
      setCarType(typeFromUrl); // Set the state for carType
      console.log("Car Type from URL:", typeFromUrl); // Log the carType value

      try {
        setLoading(true);
        let apiUrl;
        if (typeFromUrl) {
          const carTypeUpper = typeFromUrl.toUpperCase(); // Use typeFromUrl directly here
          apiUrl = `/api/cars/type/${carTypeUpper}?page=${currentPage}&size=12`;
          // apiUrl = `https://fleetifyme.com/api/cars/type/${carTypeUpper}?page=${currentPage}&size=12`;
        } else {
          apiUrl = `/api/cars/?page=${currentPage}&size=12`;
          // apiUrl = `https://fleetifyme.com/api/cars/?page=${currentPage}&size=12`;
        }

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch cars');
        }
        const data = await response.json();
        setCars(data.content); // Assuming the API returns paginated content in `content`
        setTotalPages(data.totalPages); // Assuming the API returns total pages
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCarsByType();
  }, [location.search, currentPage,]); // Re-run the effect whenever the query params or page number change

  const handleOpen = (images, index, car) => {
    setCurrentImages(images);
    setPhotoIndex(index);
    setCurrentCar(car);
    setIsOpen(true);
  };

  const handlePrevImage = () => {
    setPhotoIndex((prevIndex) => (prevIndex + currentImages.length - 1) % currentImages.length);
  };

  const handleNextImage = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const handleCloseLightbox = (e) => {
    if (e.target.className.includes('custom-lightbox')) {
      setIsOpen(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <main>
      <div className='search-page-container'>
        <section className="section__container cars__list__container">
          <h2 className="section__header"> {carType ? `${carType.charAt(0).toUpperCase() + carType.slice(1).toLowerCase()} Cars` : "Available Cars"} </h2>
          <div className="car-listing">
            {cars.map((car) => (
              <div key={car.carId} className="car-card">
                <button
                  className={`heart ${likedCarsMap[car.carId] ? 'liked' : ''}`} onClick={() => handleToggleLike(car.carId)}>
                  <FaHeart className="heart-icon" />
                </button>
                <Link to={`/car-details/${car.carId}?type=${carType || car.vehicleTypes[0]}`}>
                  <h3 className="car-card-title">
                    {`${car.vehicleMark} ${car.vehicleModel} ${car.vehicleYear}`}
                  </h3>
                  <p className="car-card-subtitle">
                    {carType
                      ? `${carType.charAt(0).toUpperCase() + carType.slice(1).toLowerCase()} ${car.seats}-Seater in Dubai`
                      : `${car.vehicleTypes[0].charAt(0).toUpperCase() + car.vehicleTypes[0].slice(1).toLowerCase() } ${car.seats}-Seater in Dubai`
                    }
                  </p>
                </Link>
                {/* Photos Section */}
                <div className="car-card-photos">
                  {car.carImages?.length > 0 && car.carImages.slice(0, 4).map((image, index) => (
                    <div key={index} className="photo-container">
                      <img
                        src={image}
                        alt={`${car.vehicleMark} ${car.vehicleModel} ${car.vehicleYear} in Dubai ${index + 1}`}
                        className={`car-photo `}
                        loading="lazy" // Lazy load each image
                        onClick={() => handleOpen(car.carImages.slice(0, 4), index, car)}
                      />
                    </div>
                  ))}
                </div>

                {/* Features Section */}
                <Link to={`/car-details/${car.carId}?type=${carType}`}
                >
                  <div className="car-card-features">
                    <FaCar className='icon' />{carType || car.vehicleTypes[0]} &nbsp;
                    <GiCarDoor className='icon' /> {car.doors} &nbsp;
                    <FaUser className='icon' /> {car.seats} &nbsp;
                    <FaSuitcase className='icon' /> {car.luggage} &nbsp;
                    <BsFuelPumpFill className='icon' /> {car.fuelType} &nbsp;
                  </div>

                  {/* Pricing and Special Features Section */}
                  <div className="car-card-details">
                    <div className="special-features">
                      <ul>
                        {car.oneDayRentIsAvailable && (
                          <li key="oneDayRent"><FaCheck /> One day Rent</li>
                        )}
                        {car.deliveryIsAvailable && (
                          <li key="carDelivery"><FaCheck /> Car Delivery</li>
                        )}
                        {car.cryptoIsAccepted && (
                          <li key="cryptoAccepted"><FaCheck /> Crypto Accepted</li>
                        )}
                        {car.insuranceIsIncluded && (
                          <li key="insuranceIncluded"><FaCheck /> Basic Insurance Included</li>
                        )}
                      </ul>
                    </div>
                    <div className="car-card-pricing">
                      <p>Price Per Day: AED {car.pricePerDay}</p>
                      {car.pricePerMonth && <p>Price Per Month: AED {car.pricePerMonth}</p>}
                    </div>
                  </div>
                </Link>

                {/* Action Buttons */}
                <div className="car-card-actions">
                  <a href="tel:+971567979085" className="callbtn call">
                    <FaPhone />
                  </a>
                  <a href="https://wa.me/+971567979085" className="callbtn whatsapp">
                    <FaWhatsapp />
                  </a>
                  <a href="https://t.me/imfleetify" className="callbtn telegram">
                    <FaTelegramPlane />
                  </a>
                </div>
              </div>
            ))}

            {/* Lightbox for high-resolution images with custom arrows and close button */}
            {isOpen && (
              <div className="custom-lightbox" onClick={handleCloseLightbox}>
                <button className="lightbox-close" onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}>
                  &times;
                </button>

                <button className="lightbox-arrow left" onClick={(e) => {
                  e.stopPropagation();
                  handlePrevImage();
                }}>
                  &#10094;
                </button>

                <img
                  src={currentImages[photoIndex]}
                  alt="High resolution car"
                  className={`lightbox-image ${photoIndex === 3 ? 'darken' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNextImage();
                  }}
                />

                <button className="lightbox-arrow right" onClick={(e) => {
                  e.stopPropagation();
                  handleNextImage();
                }}>
                  &#10095;
                </button>

                <div className="lightbox-thumbnails">
                  {currentImages.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Thumbnail ${index}`}
                      className={`lightbox-thumbnail ${index === photoIndex ? 'active' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPhotoIndex(index);
                      }}
                    />
                  ))}
                </div>

                {/* "Find Out More" Button in Lightbox only on 4th image */}
                {currentCar && photoIndex === 3 && (
                  <Link to={`/car-details/${currentCar.carId}?type=${carType}`} className="lightbox-more-details-button">
                    MORE DETAILS
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="pagination-controls">
            {/* Previous Button */}
            <button
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              PREV
            </button>

            {/* Render page numbers */}
            {renderPageNumbers()}

            {/* Next Button */}
            <button
              className="pagination-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
            >
              NEXT
            </button>
          </div>
        </section>

      </div>
    </main>
  );
};

export default CarListing;
