import React, { useState } from 'react';
import '../styles/styles.css';
import '../styles/contact.css';
import MapComponent from './MapComponent'; // Adjust the path as needed
import { FaPhone, FaEnvelope, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';
import axios from 'axios';


const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [successMessage, setSuccessMessage] = useState(''); // Success message state


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        // Clear the error for the field that is being updated
        setErrors({
            ...errors,
            [e.target.name]: '',
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.phone.trim()) {
            newErrors.phone = 'Phone number is required';
        } else if (!/^\+?[0-9]{10,15}$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone format';
        }
        if (!formData.message.trim()) newErrors.message = 'Message is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Stop form submission if there are errors
        }

        setIsLoading(true); // Disable submit button
        setSuccessMessage(''); // Clear any previous success message

        try {
            const response = await axios.post('/api/contact/sendmessage', formData)
            if (response.status === 200) {
                setSuccessMessage('Message sent successfully!');
                setFormData({ name: '', email: '', phone: '', message: '' }); // Clear form fields
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            alert('Failed to send message.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="contact_us_6">
            <div className="responsive-container-block container">
                <form className="form-box" onSubmit={handleSubmit} noValidate>
                    <div className="container-block form-wrapper">
                        <div className="mob-text">
                            <p className="text-blk contactus-head">Get in Touch</p>
                            <p className="text-blk contactus-subhead">
                                Have questions? Interested in listing your fleet with us or exploring other opportunities? We're here to help!
                            </p>
                        </div>
                        <div className="responsive-container-block" id="i2cbk">
                            <div className={`responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12 ${errors.name ? 'error' : ''}`} id="i10mt-3">
                                <p className="text-blk input-title">NAME</p>
                                <input
                                    className={`input ${errors.name ? 'input-error' : ''}`}
                                    id="ijowk-3"
                                    name="name"
                                    placeholder="Please enter name..."
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.name && <p className="error-text">{errors.name}</p>}
                            </div>
                            <div className={`responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12 ${errors.email ? 'error' : ''}`} id="ip1yp">
                                <p className="text-blk input-title">EMAIL</p>
                                <input
                                    className={`input ${errors.email ? 'input-error' : ''}`}
                                    id="ipmgh-3"
                                    name="email"
                                    placeholder="Please enter email..."
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.email && <p className="error-text">{errors.email}</p>}
                            </div>
                            <div className={`responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12 ${errors.phone ? 'error' : ''}`} id="ih9wi">
                                <p className="text-blk input-title">PHONE NUMBER</p>
                                <input
                                    className={`input ${errors.phone ? 'input-error' : ''}`}
                                    id="imgis-3"
                                    name="phone"
                                    placeholder="Please enter phone number..."
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                {errors.phone && <p className="error-text">{errors.phone}</p>}
                            </div>
                            <div className={`responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12 ${errors.message ? 'error' : ''}`} id="i634i-3">
                                <p className="text-blk input-title">WHAT DO YOU HAVE IN MIND?</p>
                                <textarea
                                    className={`textinput ${errors.message ? 'input-error' : ''}`}
                                    id="i5vyy-3"
                                    name="message"
                                    placeholder="Please enter query..."
                                    rows="5"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                                {errors.message && <p className="error-text">{errors.message}</p>}
                            </div>
                        </div>
                        <button className="submit-button btn" id="w-c-s-bgc_p-1-dm-id-2" type='submit' disabled={isLoading}>
                            {isLoading ? 'Sending...' : 'Submit'}
                        </button>
                        {successMessage && <p className="success-message">{successMessage}</p>}
                    </div>
                </form>
                <div className="responsive-cell-block wk-desk-7 wk-ipadp-12 wk-tab-12 wk-mobile-12" id="i772w">
                    <div className="map-part">
                        <p className="text-blk map-contactus-head" id="w-c-s-fc_p-1-dm-id">Reach us at</p>
                        <p className="text-blk map-contactus-subhead"></p>
                        <div className="contact-info">
                            <div className="contact-item">
                                <FaPhone className="contact-icon" />
                                <a href="tel:+971567979085" className="contact-link">
                                    +971567979085
                                </a>
                            </div>
                            <div className="contact-item">
                                <FaEnvelope className="contact-icon" />
                                <a href="mailto:info@example.com" className="contact-link">
                                    contact@fleetifyme.com
                                </a>
                            </div>
                            <div className="contact-item">
                                <FaInstagram className="contact-icon" />
                                <a href="https://www.instagram.com/fleetify_rentacar" target="_blank" rel="noopener noreferrer" className="contact-link">
                                    Follow us on Instagram
                                </a>
                            </div>
                            <div className="contact-item">
                                <FaMapMarkerAlt className="contact-icon" />
                                <span className="contact-link">
                                    Sheikh Zayed Road, Dubai, UAE
                                </span>
                            </div>
                        </div>
                        <MapComponent />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
