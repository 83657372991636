import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/styles.css';
import { Fancybox } from '@fancyapps/ui';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { FaCar, FaUser, FaSuitcase, FaPhone, FaWhatsapp, FaTelegramPlane, FaCheck } from 'react-icons/fa';
import { BsFuelPumpFill } from 'react-icons/bs';
import { GiCarDoor } from "react-icons/gi";
import { Link, useLocation } from 'react-router-dom';


const CarDetails = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('day'); // Manage the active tab
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false); // Collapsible description
  const { carId } = useParams(); // Get carId from the URL
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [car, setCar] = useState(null); // State to hold car details
  const location = useLocation();
  const [carType, setCarType] = useState(null); // Total pages from the API


  const getCarTypeFromQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('type');
  };

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const carTypeFromUrl = getCarTypeFromQueryParams;
        setCarType(carTypeFromUrl)

        setLoading(true);
        const response = await fetch(`/api/cars/${id}`);
        // const response = await fetch(`https://fleetifyme.com/api/cars/${id}`);

        if (!response.ok) {
          throw new Error('Failed to fetch car details');
        }
        const data = await response.json();
        setCar(data); // Set car details data
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCarDetails();
  }, [id]);


  // Initialize Fancybox
  useEffect(() => {
    Fancybox.bind("[data-fancybox='gallery']", {
      // Custom Fancybox options can be added here if needed
    });

    return () => {
      Fancybox.destroy(); // Cleanup Fancybox when the component is unmounted
    };
  }, [car]);

  // Handle the description expansion only on mobile screens
  const handleExpandDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!car) {
    return <div>No car details found</div>;
  }

  return (
    <main>
      <section className="section__container car-details-container">
        <div className="car-details-header">
          <h1 className="car-title"> {`${car.vehicleMark} ${car.vehicleModel} ${car.vehicleYear} in Dubai`}</h1>
          <p className="car-subtitle">{`${(carType ? carType.charAt(0).toUpperCase() + carType.slice(1).toLowerCase() : car.vehicleTypes[0])} ${car.seats}-Seater`}</p></div>

        {/* Car Images */}
        <div className="car-image-collage">
          {car.carImages.map((image, index) => (
            <a
              key={index}
              data-fancybox="gallery" // Group all images in a gallery
              href={image}
              className="car-image-collage-item"
            >
              <img
                src={image}
                alt={`Car ${index + 1}`}
                className="car-image-collage-item"
                loading='lazy'
              />
            </a>
          ))}
        </div>
        <div className='parent-container'>
          <div className='two-third'>

            {/* Features Section */}
            <div className="car-card-features">
              <FaCar className='icon' />{carType.charAt(0).toUpperCase() + carType.slice(1).toLowerCase() || car.vehicleTypes[0]} &nbsp;
              <GiCarDoor className='icon' /> {car.doors} &nbsp;
              <FaUser className='icon' /> {car.seats} &nbsp;
              <FaSuitcase className='icon' /> {car.luggage} &nbsp;
              <BsFuelPumpFill className='icon' /> {car.fuelType.charAt(0).toUpperCase() + car.fuelType.slice(1).toLowerCase()} &nbsp;
            </div>

            {/* Description Section */}
            <div className="car-description">
              <h3>Description</h3>
              <p className={`mobile-description ${isDescriptionExpanded ? 'expanded' : ''}`}>
                {window.innerWidth > 768
                  ? car.vehicleDescription // Show full description on larger screens
                  : (isDescriptionExpanded
                    ? car.vehicleDescription
                    : `${car.vehicleDescription.substring(0, 150)}...`)} {/* Limit to 150 chars on mobile */}
              </p>
              {window.innerWidth <= 768 && (
                <button
                  className="read-more-btn"
                  onClick={handleExpandDescription}
                >
                  {isDescriptionExpanded ? 'Read Less' : 'Read More'}
                </button>
              )}
            </div>

            {/* Pricing and Special Features Section */}
            <div className="car-card-details">
              <div className="special-features">
                {/* <ul>
                    {car.specialFeatures.map((feature, index) => (
                      <li key={index}><FaCheck /> {feature}</li>
                    ))}
                  </ul> */}
                <ul>
                  {car.oneDayRentIsAvailable && (
                    <li key="oneDayRent"><FaCheck /> One day Rent</li>
                  )}
                  {car.deliveryIsAvailable && (
                    <li key="carDelivery"><FaCheck /> Car Delivery</li>
                  )}
                  {car.cryptoIsAccepted && (
                    <li key="cryptoAccepted"><FaCheck /> Crypto Accepted</li>
                  )}
                  {car.insuranceIsIncluded && (
                    <li key="insuranceIncluded"><FaCheck /> Basic Insurance Included</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className='one-third'>
            {/* Pricing Card with Tabs */}
            <div className="pricing-card">
              <div className="pricing-tabs">
                <button
                  className={`pricing-tab ${activeTab === 'day' ? 'active' : ''}`}
                  onClick={() => setActiveTab('day')}
                >
                  AED {car.pricePerDay} / Day
                </button>
                <button
                  className={`pricing-tab ${activeTab === 'week' ? 'active' : ''}`}
                  onClick={() => setActiveTab('week')}
                >
                  AED {car.pricePerWeek} / Week
                </button>
                <button
                  className={`pricing-tab ${activeTab === 'month' ? 'active' : ''}`}
                  onClick={() => setActiveTab('month')}
                >
                  AED {car.pricePerMonth} / Month
                </button>
              </div>

              {/* Tab Content */}
              <div className="pricing-details">
                {activeTab === 'day' && (
                  <div className="pricing-info">
                    <ul>
                      <li>Included Mileage: {car.includedKmPerDay || "200"}km / day</li>
                      <li>Additional Charges: AED {car.additionalChargesPerKmADay || "0.50"} / km</li>
                      <li>Insurance: {car.insuranceIsIncluded ? "Basic coverage" : "Not Included"}</li>
                    </ul>
                  </div>
                )}

                {activeTab === 'week' && (
                  <div className="pricing-info">
                    <ul>
                      <li>Included Mileage: {car.includedKmPerDay * 7 || "1000"}km / week</li>
                      <li>Additional Charges: AED {car.additionalChargesPerKmADay || "0.45"} / km</li>
                      <li>Insurance: {car.insuranceIsIncluded ? "Basic coverage" : "Not Included"}</li>
                    </ul>
                  </div>
                )}

                {activeTab === 'month' && (
                  <div className="pricing-info">
                    <ul>
                      <li>Included Mileage: {car.includedKmPerDay * 30 || "4000"}km / month</li>
                      <li>Additional Charges: AED {car.additionalChargesPerKmADay || "0.35"} / km</li>
                      <li>Insurance: {car.insuranceIsIncluded ? "Basic coverage" : "Not Included"}</li>
                    </ul>
                  </div>
                )}
              </div>


              {/* Action Buttons */}
              <div className="car-card-actions">
                <a href="tel:+971567979085" className="callbtn call">
                  <FaPhone />
                </a>
                <a href="https://wa.me/+971567979085" className="callbtn whatsapp">
                  <FaWhatsapp />
                </a>
                <a href="https://t.me/imfleetify" className="callbtn telegram">
                  <FaTelegramPlane />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <SimilarCars cars={cars} /> */}
      </section>
    </main>
  );
};

export default CarDetails;
