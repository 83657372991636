import React from 'react';
import ReactDOM from 'react-dom/client'; // Update the import
import App from './App';
import ResizeObserver from 'resize-observer-polyfill';

window.ResizeObserver = ResizeObserver;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <App />
);

if (process.env.NODE_ENV === 'development') {
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (args[0].includes('ResizeObserver loop completed with undelivered notifications')) {
      return;
    }
    originalConsoleError(...args);
  };
}
