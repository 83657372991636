// Navbar.js
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/styles.css';
import '../styles/login.css';
import '../styles/modal.css';
import LoginSignup from '../components/LoginSignUp'
import { useAuth } from '../components/AuthContext';
import { RiMenuLine } from 'react-icons/ri';


const Navbar = ({ openModal, handleSignOut, handleAuthSuccess }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const handleClickOutside = (e) => {
    const menu = document.getElementById('nav-links');
    const menuButton = document.getElementById('menu-btn');

    if (menu && !menu.contains(e.target) && menuButton && !menuButton.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  const closeMenu = () => setIsMenuOpen(false);

  const handleNavigation = (e, path) => {
    e.preventDefault();
    closeMenu();
    if (window.location.pathname === '/') {
      const section = document.querySelector(path);
      section && section.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/');
      setTimeout(() => {
        const section = document.querySelector(path);
        section && section.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  };

  const handleCustomNavigation = (path) => {
    navigate(path);
    closeMenu();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleOpenSignUp = useCallback(() => {
    closeMenu();
    openModal(() => <LoginSignup defaultTab="signup" onSuccess={handleAuthSuccess}/>);
  }, [closeMenu, openModal]);
  
  const handleOpenLogin = useCallback(() => {
    closeMenu();
    openModal(() => <LoginSignup defaultTab="login" onSuccess={handleAuthSuccess}/>);
  }, [closeMenu, openModal]);

  return (
    <nav>
      <div className="nav__header">
        <div className="nav__logo">
          <Link to="/">fleetify</Link>
        </div>
        <div className="nav__menu__btn" id="menu-btn" onClick={toggleMenu}>
          <i > <RiMenuLine /></i>
        </div>
      </div>
      <ul className={`nav__links ${isMenuOpen ? 'open' : ''}`} id="nav-links">
        <li><a href="/" onClick={closeMenu}>Home</a></li>
        <li><a href="/cars-listing" onClick={(e) => { e.preventDefault(); handleCustomNavigation('/cars-listing'); }}>All Cars</a></li>
        <li><a href="#car-types" onClick={(e) => handleNavigation(e, '#car-types')}>Car Types</a></li>
        <li><a href="#service" onClick={(e) => handleNavigation(e, '#service')}>Services</a></li>
        <li><a href="/contactus" onClick={(e) => { e.preventDefault(); handleCustomNavigation('/contactus'); }}>Contact Us</a></li>

        {/* Conditional Rendering for Authentication */}
        {user ? (
          <>
            <li><Link className="nav__links__btn btn" to="/userpage" onClick={closeMenu}>My Account</Link></li>
            <li><button className="nav__links__btn btn" onClick={() => { closeMenu(); handleSignOut(); }}>Sign Out</button></li>
          </>
        ) : (
          <>
            <li>
              <button className="nav__links__btn btn" onClick={handleOpenSignUp}>Sign Up</button>
            </li>
            <li><button className="nav__links__btn btn" onClick={handleOpenLogin}>Sign In</button>
            </li>
          </>
        )}
      </ul>

      <div className="nav__btns">
        {user ? (
          <>
            <Link to="/userpage" className="btn btn__tertiary">My Account</Link>
            <button className="btn btn__secondary" onClick={handleSignOut}>Sign Out</button>
          </>
        ) : (
          <>
            <button className="btn btn__primary" onClick={handleOpenSignUp}>Sign Up</button>
            <button className="btn btn__secondary" onClick={handleOpenLogin}>Sign In</button>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
