import React from 'react';
import '../styles/styles.css';

const Footer = () => (
  <footer>
    {/* <div className="section__container footer__container">
      <div className="footer__col">
        <h4>Our Products</h4>
        <ul className="footer__links">
          <li><a href="#">Career</a></li>
          <li><a href="#">Hotels</a></li>
          <li><a href="#">Cars</a></li>
          <li><a href="#">Packages</a></li>
          <li><a href="#">Features</a></li>
          <li><a href="#">Priceline</a></li>
        </ul>
      </div>
    </div> */}
    <div className="section__container footer__bar">
      <h4>Fleetify</h4>
      <p>Copyright © 2024. All rights reserved.</p>
      <ul className="footer__socials">
        <li><a href="#"><i className="ri-facebook-fill"></i></a></li>
        <li><a href="#"><i className="ri-twitter-fill"></i></a></li>
        <li><a href="https://www.instagram.com/fleetify_rentacar" target="_blank" rel="noopener noreferrer"><i className="ri-instagram-fill"></i></a></li>
      </ul>
    </div>
  </footer>
);

export default Footer;