import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GiCarDoor } from 'react-icons/gi';
import { BsFuelPumpFill } from 'react-icons/bs';
import { FaCar, FaUser, FaSuitcase, FaPhone, FaWhatsapp, FaTelegramPlane, FaCheck } from 'react-icons/fa';
import { useAuth } from './AuthContext';


const UserPage = () => {
  const { user } = useAuth(); 
  const [likedCars, setLikedCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentCar, setCurrentCar] = useState(null);
  const [carType, setCarType] = useState(null); // Total pages from the API


  const handleOpen = (images, index, car) => {
    setCurrentImages(images);
    setPhotoIndex(index);
    setCurrentCar(car);
    setIsOpen(true);
  };

  const handlePrevImage = () => {
    setPhotoIndex((prevIndex) => (prevIndex + currentImages.length - 1) % currentImages.length);
  };

  const handleNextImage = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % currentImages.length);
  };

  const handleCloseLightbox = (e) => {
    if (e.target.className.includes('custom-lightbox')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const fetchLikedCars = async () => {
      console.log(user)
      if (!user) {
        console.log(user)
        console.warn('No user ID found. Skipping fetch.');
        return;
      }

      try {
        setLoading(true);
        console.log(`Fetching liked cars for user ID: ${user.uid}`);

        const response = await fetch(`api/user/liked-cars/${user.uid}`);
        if (!response.ok) {
          throw new Error('Failed to fetch liked cars');
        }
        const data = await response.json();
        console.log('Fetched data:', data);

        setLikedCars(data || []); // Assuming data structure has `content`
      } catch (error) {
        console.error('Error fetching liked cars:', error.message);
        setError('Could not fetch liked cars. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchLikedCars();
  }, [user]);

  if (loading) return <p>Loading liked cars...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className='search-page-container'>
      <section className="section__container cars__list__container">
        <h2 className="section__header"> Your Wishlist </h2>
        <div className="car-listing">
          {likedCars.map((car) => (
            <div key={car.carId} className="car-card">
              <Link to={`/car-details/${car.carId}?type=${carType || car.vehicleTypes[0]}`}
              >
                <h3 className="car-card-title">
                  {`${car.vehicleMark} ${car.vehicleModel} ${car.vehicleYear}`}
                </h3>
                <p className="car-card-subtitle">
                  {carType ? `${carType.charAt(0).toUpperCase() + carType.slice(1).toLowerCase()} ${car.seats}-Seater in Dubai` : ''}
                </p>
              </Link>
              {/* Photos Section */}
              <div className="car-card-photos">
                {car.carImages?.length > 0 && car.carImages.slice(0, 4).map((image, index) => (
                  <div key={index} className="photo-container">
                    <img
                      src={image}
                      alt={`${car.vehicleMark} ${car.vehicleModel} ${car.vehicleYear} in Dubai ${index + 1}`}
                      className={`car-photo `}
                      onClick={() => handleOpen(car.carImages.slice(0, 4), index, car)}
                      loading='lazy'
                    />
                  </div>
                ))}
              </div>

              {/* Features Section */}
              <Link to={`/car-details/${car.carId}?type=${carType}`}
              >
                <div className="car-card-features">
                  <FaCar className='icon' />{carType || car.vehicleTypes[0]} &nbsp;
                  <GiCarDoor className='icon' /> {car.doors} &nbsp;
                  <FaUser className='icon' /> {car.seats} &nbsp;
                  <FaSuitcase className='icon' /> {car.luggage} &nbsp;
                  <BsFuelPumpFill className='icon' /> {car.fuelType} &nbsp;
                </div>

                {/* Pricing and Special Features Section */}
                <div className="car-card-details">
                  <div className="special-features">
                    <ul>
                      {car.oneDayRentIsAvailable && (
                        <li key="oneDayRent"><FaCheck /> One day Rent</li>
                      )}
                      {car.deliveryIsAvailable && (
                        <li key="carDelivery"><FaCheck /> Car Delivery</li>
                      )}
                      {car.cryptoIsAccepted && (
                        <li key="cryptoAccepted"><FaCheck /> Crypto Accepted</li>
                      )}
                      {car.insuranceIsIncluded && (
                        <li key="insuranceIncluded"><FaCheck /> Basic Insurance Included</li>
                      )}
                    </ul>
                  </div>
                  <div className="car-card-pricing">
                    <p>Price Per Day: AED {car.pricePerDay}</p>
                    {car.pricePerMonth && <p>Price Per Month: AED {car.pricePerMonth}</p>}
                  </div>
                </div>
              </Link>

              {/* Action Buttons */}
              <div className="car-card-actions">
                <a href="tel:+971567979085" className="callbtn call">
                  <FaPhone />
                </a>
                <a href="https://wa.me/+971567979085" className="callbtn whatsapp">
                  <FaWhatsapp />
                </a>
                <a href="https://t.me/imfleetify" className="callbtn telegram">
                  <FaTelegramPlane />
                </a>
              </div>
            </div>
          ))}

          {/* Lightbox for high-resolution images with custom arrows and close button */}
          {isOpen && (
            <div className="custom-lightbox" onClick={handleCloseLightbox}>
              <button className="lightbox-close" onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}>
                &times;
              </button>

              <button className="lightbox-arrow left" onClick={(e) => {
                e.stopPropagation();
                handlePrevImage();
              }}>
                &#10094;
              </button>

              <img
                src={currentImages[photoIndex]}
                alt="High resolution car"
                className={`lightbox-image ${photoIndex === 3 ? 'darken' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleNextImage();
                }}
              />

              <button className="lightbox-arrow right" onClick={(e) => {
                e.stopPropagation();
                handleNextImage();
              }}>
                &#10095;
              </button>

              <div className="lightbox-thumbnails">
                {currentImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index}`}
                    className={`lightbox-thumbnail ${index === photoIndex ? 'active' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPhotoIndex(index);
                    }}
                  />
                ))}
              </div>

              {/* "Find Out More" Button in Lightbox only on 4th image */}
              {currentCar && photoIndex === 3 && (
                <Link to={`/car-details/${currentCar.carId}?type=${carType}`} className="lightbox-more-details-button">
                  MORE DETAILS
                </Link>
              )}
            </div>
          )}
        </div>
      </section>

    </div>
  );
};

export default UserPage;
