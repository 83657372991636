// components/HomePage.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CarTypesSection from './CarTypesSection';
import StepsSection from './StepsSection';
import ServiceSection from './ServiceSection';
import DownloadSection from './DownloadSection';
import '../styles/styles.css';
import LoginSignUp from './LoginSignUp'; // Your login/signup modal component

const HomePage = ({ openModal }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.openSignupModal) {
      openModal(LoginSignUp);

      // Clear `openSignupModal` after opening the modal
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, openModal, navigate]);

  return (
    <div>
      <CarTypesSection />
      <StepsSection />
      <ServiceSection />
      <DownloadSection />
    </div>
  );
};

export default HomePage;
