import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAsphcJA22s_RMA-6naeCXLs0QmgTpsVac",
  authDomain: "fleetify-fdd9d.firebaseapp.com",
  projectId: "fleetify-fdd9d",
  storageBucket: "fleetify-fdd9d.appspot.com",
  messagingSenderId: "776086599107",
  appId: "1:776086599107:web:1b44d68c4c2798d12bb976",
  measurementId: "G-EQ80039VCS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
