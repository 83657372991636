import { RiMapPinFill, RiCalendarCheckFill, RiBookmark3Fill } from 'react-icons/ri';
import '../styles/styles.css';

const StepsSection = () => {
  return (
    <section className="section__container steps__container" id="rent">
      <p className="section__subheader">HOW IT WORKS</p>
      <h2 className="section__header">Rent following 3 working steps</h2>
      <div className="steps__grid">
        <div className="steps__card">
          <span><RiMapPinFill /></span>
          <h4>Choose a location</h4>
          <p>
            Select your desired rental location from our extensive network of
            car rental spots.
          </p>
        </div>
        <div className="steps__card">
          <span><RiCalendarCheckFill /></span>
          <h4>Pick-up date</h4>
          <p>
            Specify the date and time you wish to pick up your car with flexible
            scheduling options.
          </p>
        </div>
        <div className="steps__card">
          <span><RiBookmark3Fill /></span>
          <h4>Book your car</h4>
          <p>
            Browse through our wide range of vehicles and choose the one that
            best suits your needs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
