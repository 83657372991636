// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, ScrollRestoration } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './components/HomePage';
import CarListing from './components/CarListing';
import ImageCarousel from './components/ImageCarousel';
import CarDetail from './components/CarDetail';
import ContactUs from './components/Contact';
import UserPage from './components/UserPage';
import Modal from './components/Modal';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import ScrollReveal from './components/ScrollRevealComponent';


function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalContent, setModalContent] = useState(null);

  const openModal = (Component) => {
    setModalContent(() => Component);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleAuthSuccess = () => {
    closeModal();
  };

  return (
    <AuthProvider>
      <Router>
        <Layout openModal={openModal} handleAuthSuccess={handleAuthSuccess}>
          <Routes>
            <Route path="/cars-listing" element={<CarListing openModal={openModal}/>}/>
            <Route path="/car-details/:id" element={<CarDetail  openModal={openModal} />} />
            <Route path="/" element={<HomePage openModal={openModal} />} />
            <Route path="/scroll-reveal" element={<ScrollReveal />} />
            <Route path="/carousel" element={<ImageCarousel />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/userpage" element={<ProtectedRoute component={UserPage} />} />
          </Routes>
          {isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              {ModalContent && <ModalContent onSuccess={handleAuthSuccess}/>}
            </Modal>
          )}
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
