// components/ScrollRevealComponent.js
import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import '../styles/styles.css';

function ScrollRevealComponent() {
  useEffect(() => {
    const scrollRevealOption = {
      distance: '50px',
      origin: 'bottom',
      duration: 1000,
    };

    ScrollReveal().reveal('.header__image img', {
      ...scrollRevealOption,
      origin: 'right',
    });
    ScrollReveal().reveal('.header__content h1', {
      ...scrollRevealOption,
      delay: 500,
    });
    ScrollReveal().reveal('.header__content p', {
      ...scrollRevealOption,
      delay: 1000,
    });
    ScrollReveal().reveal('.header__links', {
      ...scrollRevealOption,
      delay: 1500,
    });

    // More ScrollReveal animations
    ScrollReveal().reveal('.steps__card', {
      ...scrollRevealOption,
      interval: 500,
    });
    ScrollReveal().reveal('.service__image img', {
      ...scrollRevealOption,
      origin: 'left',
    });
    ScrollReveal().reveal('.service__content .section__subheader', {
      ...scrollRevealOption,
      delay: 500,
    });
    ScrollReveal().reveal('.service__content .section__header', {
      ...scrollRevealOption,
      delay: 1000,
    });
    ScrollReveal().reveal('.service__list li', {
      ...scrollRevealOption,
      delay: 1500,
      interval: 500,
    });
    ScrollReveal().reveal('.experience__card', {
      duration: 1000,
      interval: 500,
    });
    ScrollReveal().reveal('.download__image img', {
      ...scrollRevealOption,
      origin: 'right',
    });
    ScrollReveal().reveal('.download__content .section__header', {
      ...scrollRevealOption,
      delay: 500,
    });
    ScrollReveal().reveal('.download__content p', {
      ...scrollRevealOption,
      delay: 1000,
    });
    ScrollReveal().reveal('.download__links', {
      ...scrollRevealOption,
      delay: 1500,
    });
  }, []); // Empty dependency array ensures this runs once on component mount

  return (
    <div>
      {/* Add your content that needs ScrollReveal animations */}
    </div>
  );
}

export default ScrollRevealComponent;
