import React from 'react';
import headerImg from '../assets/header.png';
import appStoreImg from '../assets/store.jpg';
import playImg from '../assets/play.png';
import Navbar from './Navbar';
import '../styles/styles.css';

const Header = () => (
  <header>
    <div className="section__container header__container" id="home">
      <div className="header__image">
        <img src={headerImg} alt="header" />
      </div>
      <div className="header__content">
        <h1>Looking to save more on your rental car?</h1>
        <p>Choose from a variety of vehicles and take advantage of our exclusive local deals.</p>
        {/* <div className="header__links">
          <a href="#"><img src={appStoreImg} alt="app store" /></a>
          <a href="#"><img src={playImg} alt="play" /></a>
        </div> */}
      </div>
    </div>
  </header>
);

export default Header;