// components/CarTypesSection.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import sedan from '../assets/sedan.png';
import sports from '../assets/sports.png';
import suv from '../assets/suv.png';
import monthly from '../assets/monthly.png';
import convert from '../assets/convertable.png';
import coupe from '../assets/coupe.png';
import '../styles/styles.css';

const CarTypesSection = () => (
  <section className="section__container car__types__container" id="car-types">
    <p className="section__subheader">EXPLORE CAR TYPES</p>
    <h2 className="section__header">Choose Your Perfect Ride</h2>

    <div className="car__types__content">
      <div className="car__types__grid">
        <Link to="/cars-listing?type=sedan" className="car__type">
          <div className="car__type__image">
            <img src={sedan} alt="Sedan Cars" />
            <h3 className="car__type__title">SEDAN</h3>
          </div>
        </Link>

        <Link to="/cars-listing?type=sports" className="car__type">
          <div className="car__type__image">
            <img src={sports} alt="Sports Cars" />
            <h3 className="car__type__title">SPORT</h3>
          </div>
        </Link>

        <Link to="/cars-listing?type=suv" className="car__type">
          <div className="car__type__image">
            <img src={suv} alt="SUVs" />
            <h3 className="car__type__title">SUV</h3>
          </div>
        </Link>

        <Link to="/cars-listing?type=coupe" className="car__type">
          <div className="car__type__image">
            <img src={coupe} alt="Coupe" />
            <h3 className="car__type__title">COUPE</h3>
          </div>
        </Link>

        <Link to="/cars-listing?type=monthly" className="car__type">
          <div className="car__type__image">
            <img src={monthly} alt="Monthly Rentals" />
            <h3 className="car__type__title">MONTHLY</h3>
          </div>
        </Link>

        <Link to="/cars-listing?type=convertible" className="car__type">
          <div className="car__type__image">
            <img src={convert} alt="Convertibles" />
            <h3 className="car__type__title">CONVERTIBLE</h3>
          </div>
        </Link>
      </div>
    </div>
  </section>
);

export default CarTypesSection;
