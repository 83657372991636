import React from 'react';
import { RiPriceTag3Fill, RiWalletFill, RiCustomerServiceFill } from 'react-icons/ri';
import service from '../assets/service.png';
import '../styles/styles.css';

const ServiceSection = () => {
  return (
    <section className="section__container service__container" id="service">
      <div className="service__image">
        <img src={service} alt="service" />
      </div>
      <div className="service__content">
        <p className="section__subheader">BEST SERVICES</p>
        <h2 className="section__header">
          Feel the best experience with our rental deals
        </h2>
        <ul className="service__list">
          <li>
            <span><RiPriceTag3Fill /></span>
            <div>
              <h4>Deals for every budget</h4>
              <p>
                From economy cars to luxury vehicles, we have something for
                everyone, ensuring you get the best value for your money.
              </p>
            </div>
          </li>
          <li>
            <span><RiWalletFill /></span>
            <div>
              <h4>Best price guarantee</h4>
              <p>
                We ensure you get competitive rates in the market, so you can
                book with confidence knowing you're getting the best deal.
              </p>
            </div>
          </li>
          <li>
            <span><RiCustomerServiceFill /></span>
            <div>
              <h4>Support 24/7</h4>
              <p>
                Our dedicated team is available 24/7 to assist you with any
                questions or concerns, ensuring a smooth rental experience.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ServiceSection;
