import React, { useState } from 'react';
import { auth } from './FirebaseConfig';
import { GoogleAuthProvider, getAdditionalUserInfo } from "firebase/auth";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/login.css';
import google_icon from '../assets/google-icon.svg';

const LoginSignUp = ({ onSuccess = () => {}, defaultTab = "login" }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(defaultTab === "login");
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const navigate = useNavigate(); // React Router navigation
  const location = useLocation(); // Get the current location

  const handleTabSwitch = (isLogin) => {
    setIsLogin(isLogin);
    setEmail('');
    setPassword('');
    setEmailError('');
    setPasswordError('');
    setResetMessage('');
  };

  const validateForm = () => {
    let isValid = true;
    setEmailError('');
    setPasswordError('');

    if (!email) {
      setEmailError('Email is required');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Please enter a valid email');
      isValid = false;
    }

    if (!password) {
      setPasswordError('Password is required');
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters');
      isValid = false;
    }

    return isValid;
  };

  const handleNavigation = () => {
    const carDetailsPattern = /^\/car-details\/.*/; // Regex pattern to match '/car-details/*'
  
    // Use AND to ensure navigation only if the location doesn't match either pattern
    if (!carDetailsPattern.test(location.pathname) && location.pathname !== '/cars-listing') {
      navigate('/userpage');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    try {
      let userCredential;
      if (isLogin) {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('User signed in:', userCredential.user);
      } else {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        console.log('User signed up:', userCredential.user);
  
        // Additional backend POST request for new user
        const newUser = {
          xref: userCredential.user.uid,
          email: userCredential.user.email,
          // Add any additional user fields here if needed
        };
  
        const response = await fetch('/api/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newUser),
        });
  
        if (!response.ok) {
          throw new Error('Failed to save new user to backend.');
        }
  
        console.log('New user saved to backend successfully');
      }
  
      handleNavigation();
      onSuccess();
      setPasswordError('');
      
    } catch (error) {
      console.error('Error:', error); // Logs the full error object
      if (error.code === 'auth/email-already-in-use') {
        setPasswordError('This email is already in use.');
      } else if (error.code === 'auth/invalid-email') {
        setPasswordError('Invalid email format.');
      } else if (error.code === 'auth/weak-password') {
        setPasswordError('Password should be at least 6 characters.');
      } else if (error.code === 'auth/network-request-failed') {
        setPasswordError('Network error, please try again later.');
      } else if (error.code === 'auth/invalid-credential') {
        setPasswordError('Invalid email or password.');
      } else {
        setPasswordError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider); // Sign in with Google
  
      // Check if the user is new
      const addionalUserInfo = getAdditionalUserInfo(userCredential);
      const isNewUser = addionalUserInfo.isNewUser;

      if (isNewUser) {
        // Backend POST request for new user
        const newUser = {
          xref: userCredential.user.uid,
          email: userCredential.user.email,
          // Add additional fields as needed
        };
  
        const response = await fetch('/api/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newUser),
        });
  
        if (!response.ok) {
          throw new Error('Failed to save new Google user to backend.');
        }
  
        console.log('New Google user saved to backend successfully');
      } else {
        console.log('Existing Google user logged in');
      }
  
      handleNavigation();
      onSuccess();
    } catch (error) {
      console.error("Error during Google sign-in:", error.message);
    }
  };
  

  const handleForgotPassword = async () => {
    setResetMessage('');
    setPasswordError('');
    setPasswordSuccess('');
    if (!email) {
      setPasswordError('Please enter your email to reset your password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setPasswordSuccess('Password reset email sent! Check your inbox.');
    } catch (error) {
      if (error.code === 'auth/invalid-email') {
        setPasswordError('Invalid email.');
      } else {
        setPasswordError(error.message);
      }
    }
  };

  return (
    <div className="form">
      <ul className="tab-group">
        <li className={`tab ${isLogin ? 'active' : ''}`} onClick={() => handleTabSwitch(true)}>
          <a href="#login">Sign In</a>
        </li>
        <li className={`tab ${!isLogin ? 'active' : ''}`} onClick={() => handleTabSwitch(false)}>
          <a href="#signup">Sign Up</a>
        </li>
      </ul>

      <form onSubmit={handleSubmit} className="form-content" noValidate>
        <div className="field-wrap">
          <input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="placeholder-text"
          />
          {emailError && <p className="error-text">{emailError}</p>}
        </div>
        <div className="field-wrap">
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="placeholder-text"
          />
          {passwordError && <p className="error-text">{passwordError}</p>}
          {passwordSuccess && <p className="success-message">{passwordSuccess}</p>}
        </div>

        <p className="forgot-password" onClick={handleForgotPassword}>
          Forgot Password?
        </p>

        <button type="submit" className="button button-block">
          {isLogin ? 'Log In' : 'Sign Up'}
        </button>
        <button type="button" className="button google-signin-button" onClick={handleGoogleSignIn}>
          <img loading="lazy" width="22" height="20" alt="google icon" src={google_icon} />
          <span>Sign in with Google</span>
        </button>
      </form>
    </div>
  );
};

export default LoginSignUp;
